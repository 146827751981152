//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cards: {
      type: Array,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
}
